import React, { FC, useCallback, useEffect, useRef, createContext, useState } from 'react';

import useActiveWeb3React from '@hooks/useActiveWeb3React';
import { injected, walletConnect } from '@constants/connectors.constants';
import Loader from '@components/Loader';

type AuthContextType = {
  connectMetaMask: () => void
  connectWalletConnect: () => void
  logOut: () => void
}

export const AuthorizationContext = createContext<null | AuthContextType>(null);

export const AuthorizationContextProvider: FC = ({ children }) => {

  const { activate, deactivate, account, chainId } = useActiveWeb3React();
  const mounted = useRef(false);

  const [isWalletConnected, setIsWalletConnected] = useState(false);

  const connectMetaMask = useCallback(async () => {
    try {
      await activate(injected);
      localStorage.setItem('auth', 'MetaMask');
    } catch (e) {
      console.error(e);
      localStorage.removeItem('auth');
    }

  }, [activate]);

  const connectWalletConnect = useCallback(async () => {
    try {
      await activate(walletConnect);
      localStorage.setItem('auth', 'WalletConnect');
    } catch (e) {
      console.error(e);
      localStorage.removeItem('auth');
    }

  }, [activate]);

  const logOut = useCallback(() => {
    deactivate();
    localStorage.removeItem('auth');
    localStorage.removeItem('walletconnect');
  }, [deactivate]);

  useEffect(() => {
    if (!mounted.current) mounted.current = true;
    else if (!account) {
      localStorage.removeItem('auth');
    }
  }, [account, chainId]);

  useEffect(() => {
    (async () => {
      if (localStorage.getItem('auth') === 'MetaMask') {
        await connectMetaMask();
      }
      if (localStorage.getItem('auth') === 'WalletConnect') {
        await connectWalletConnect();
      }
      setIsWalletConnected(true);
    })();
  }, [connectMetaMask, connectWalletConnect]);


  return (
    <AuthorizationContext.Provider value={{ connectMetaMask, connectWalletConnect, logOut }}>
      {isWalletConnected ? children : <Loader />}
    </AuthorizationContext.Provider>
  );
};
