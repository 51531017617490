import React, { FC } from 'react';
import { Typography } from '@mui/material';

import Button from '@components/Button';

import useActiveWeb3React from '@hooks/useActiveWeb3React';

import { BUTTON_TYPES } from '@constants/style.constants';
import { AlertTriangle } from '@constants/icons.constants';
import { NETWORK_CONNECT_PARAMS } from '@constants/chain.constants';

import WalletProviderModal from '@components/modals/WalletProviderModal';

import { useModal } from '@hooks/useModal';

import useStyles from './styles.module';

const ConnectWallet: FC = () => {
  const classes = useStyles();
  const { account } = useActiveWeb3React();
  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal/>);

  const handleChangeNetwork = async () => {
    // @ts-ignore
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: NETWORK_CONNECT_PARAMS,
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.wrap}>
        {
          account ? (
            <>
              <div>{AlertTriangle}</div>
              <div><Typography variant='h4'>WRONG NETWORK DETECTED</Typography></div>
              <div><Typography fontSize={14}>Please connect to either Fantom</Typography></div>
              <Button type={BUTTON_TYPES.SECONDARY} className={classes.button} onClick={handleChangeNetwork}>Change Chain</Button>
            </>
          ): (
            <div>
              <div><Typography variant='h4'>Connect a Wallet</Typography></div>
              <Button type={BUTTON_TYPES.SECONDARY} className={classes.button} onClick={onPresentWalletProviderModal}>Connect Wallet</Button>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default ConnectWallet;
