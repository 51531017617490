export const PUBLIC_RPC_1 = 'https://rpc.ftm.tools';
export const PUBLIC_RPC_2 = 'https://rpc.fantom.network';
export const PUBLIC_TESTNET_RPC_2 = 'https://bsc-dataseed1.binance.org';

export enum CHAIN_ID {
  MAINNET = 250,
  TESTNET = 4002,
  BSC_TESTNET = 97
}

export const GAS_LIMIT_MULTIPLIER = 120;
export const AWAILABLE_CHAIN_IDS = [CHAIN_ID.MAINNET, CHAIN_ID.TESTNET, CHAIN_ID.BSC_TESTNET];
export const CURRENT_CHAIN_ID = process.env.REACT_APP_ENV === 'production' ? CHAIN_ID.MAINNET : CHAIN_ID.TESTNET;
export const MIN_TX_WAIT_CONFIRMATIONS = 3;

export const NETWORK_CONNECT_PARAMS = process.env.REACT_APP_ENV === 'production' ? [
  {
    chainId: '0xfa',
    chainName: 'Fantom Opera',
    nativeCurrency: {
      name: 'Fantom Token',
      symbol: 'FTM',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.ftm.tools/'],
    blockExplorerUrls: ['https://ftmscan.com/'],
  },
]
  :
  [
    {
      chainId: '0xfa2',
      chainName: 'Fantom Opera Testnet',
      nativeCurrency: {
        name: 'Fantom Token',
        symbol: 'FTM',
        decimals: 18,
      },
      rpcUrls: ['https://rpc.testnet.fantom.network/'],
      blockExplorerUrls: ['https://testnet.ftmscan.com/'],
    },
  ];
