import React, { FC } from 'react';
import classNames from 'classnames';

import { CloseToastIcon, SuccessIcon, ErrorIcon, WarningIcon, InfoIcon } from '@constants/icons.constants';

import useStyles from './styles.module';

const TOAST_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};


interface Props {
  appearance: any
  onDismiss: any
}

const CustomToast: FC<Props> = ({ appearance, children, onDismiss }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.toast, {
      [classes.success]: appearance === TOAST_TYPE.SUCCESS,
      [classes.error]: appearance === TOAST_TYPE.ERROR,
      [classes.warning]: appearance === TOAST_TYPE.WARNING,
      [classes.info]: appearance === TOAST_TYPE.INFO,
    })}>
      {appearance === TOAST_TYPE.SUCCESS && SuccessIcon}
      {appearance === TOAST_TYPE.ERROR && ErrorIcon}
      {appearance === TOAST_TYPE.WARNING && WarningIcon}
      {appearance === TOAST_TYPE.INFO && InfoIcon}
      {children}
      <button onClick={onDismiss}>{CloseToastIcon}</button>
    </div>
  );
};

export default CustomToast;
