import React from 'react';
import styled, { keyframes } from 'styled-components';

import { useStyles } from './styles.module';

const logo: string = require('@assets/img/headerIcon.svg').default;

const rotate = keyframes`
  0% { transform: scale(1); }
  60% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const LoaderComponent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 100px;
  width: 100px;
  animation: ${rotate} 800ms linear infinite;
`;

const Loader = ({ className }) => {
  const classes = useStyles();
  return (
    <LoaderComponent className={className}>
      <img src={logo} alt="loader" className={classes.loaderImg} />
    </LoaderComponent>
  );

};

Loader.defaultProps = {
  className: '',
};

export default Loader;
