import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  toast: {
    display: 'flex',
    alignItems: 'center',
    gap: '14px',
    padding: '16px 20px',
    borderRadius: '8px',
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Poppins',

    '& button': {
      display: 'flex',
      textAlign: 'center',
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
    }
  },
  success: {
    background: '#84D353',
  },
  error: {
    background: '#F46868',
  },
  warning: {
    background: '#FF9E68',
  },
  info: {
    background: '#9ABCFF',
  },
}));

export default useStyles;
