export const BUTTON_SIZES = {
    M: 'medium',
    L: 'large',
    S: 'small',
};

export enum BUTTON_TYPES {
    SECONDARY = 'secondary',
    PRIMARY = 'primary',
    DEFAULT = 'default',
    APPROVAL = 'approval',
    METAMASK = 'metamask',
    CASINO = 'casino'
}
