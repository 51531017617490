import _ from 'lodash';

import { CHARACTERS_NFT_TYPES } from '@constants/game.constants';
import { HnO } from '@contracts/abis/types';
import { CharacterNft, ITraits, INftMetadata, ContractWithAbi, Traits } from '../types';
import { decodeMetadata } from './decodeMetadata';
import { batchAsync } from './batchAsync';
import { multicall, batchMulticall } from './multicall';
import { toBN } from './numberFormatters';
import { retryAsync } from './retryAsync';

const nftsMetadataCache: Map<number, INftMetadata> = new Map();
const nftsTraitsCache: Map<number, Traits> = new Map();

export async function fetchCachedMetadata(nftIds: number[], nftContract: ContractWithAbi<HnO>): Promise<Map<number, INftMetadata>> {
  if (nftIds.length) {
    // split by 5 call per multicall
    const nftsIdsChunks = _.chunk(nftIds, 5);
    // consistently call each multicall request and set result into cache set
    await batchAsync(nftsIdsChunks.map(async (chunkNftIds) => {
      const callsTokenUri = chunkNftIds.map((id: number) => ({ name: 'tokenURI', params: [id] }));
      const batchResponse = await retryAsync(() => multicall<string>(nftContract, callsTokenUri), 200, 2);
      chunkNftIds.forEach((id, index) => {
        const tokenMetadata = batchResponse[index][0];
        const metadata = decodeMetadata<INftMetadata>(tokenMetadata);
        nftsMetadataCache.set(id, metadata);
      });
    }), 5, 100);
  }
  return nftsMetadataCache;
}

export async function fetchCachedTraits(nftIds: number[], nftContract: ContractWithAbi<HnO>): Promise<Map<number, Traits>> {
  if (nftIds.length) {
    // split by 5 call per multicall
    const nftsIdsChunks = _.chunk(nftIds, 5);
    // consistently call each multicall request and set result into cache set
    await batchAsync(nftsIdsChunks.map(async (chunkNftIds) => {
      const callsNftsTraits = chunkNftIds.map((id: number) => ({ name: 'getTokenTraits', params: [id] }));
      const batchResponse = await retryAsync(() => multicall<ITraits>(nftContract, callsNftsTraits), 200, 2);
      chunkNftIds.forEach((id, index) => {
        const nftTraits = batchResponse[index][0];
        nftsTraitsCache.set(id, {
          rank: toBN(nftTraits.rankIndex),
          type: nftTraits.isHamster ? CHARACTERS_NFT_TYPES.HAMSTER : CHARACTERS_NFT_TYPES.OWL,
        });
      });
    }), 5, 100);
  }
  return nftsTraitsCache;
}


async function fetchTraits(nftIds: number[], nftContract: ContractWithAbi<HnO>): Promise<Map<number, Traits>> {
  const nftsForFetch = nftIds.filter((id) => !nftsTraitsCache.has(id));

  if (nftsForFetch.length) {
    const callsTokenUri = nftsForFetch.map((id: number) => ({ name: 'getTokenTraits', params: [id] }));

    const nftsTraits = await retryAsync(() => batchMulticall<string>(nftContract, callsTokenUri, 3), 200, 3);

    nftsForFetch.forEach((nftId: number, index: number) => {
      const nftsTraitsData = nftsTraits[index][0];
      nftsTraitsCache.set(nftId, {
        rank: toBN(nftsTraitsData.rankIndex),
        type: nftsTraitsData.isHamster ? CHARACTERS_NFT_TYPES.HAMSTER : CHARACTERS_NFT_TYPES.OWL,
      });
    });
  }
  return nftsTraitsCache;
}

async function fetchMetadata(nftIds: number[], nftContract: ContractWithAbi<HnO>): Promise<Map<number, INftMetadata>> {
  const nftsForFetch = nftIds.filter((id) => !nftsMetadataCache.has(id));

  if (nftsForFetch.length) {
    const callsTokenUri = nftsForFetch.map((id: number) => ({ name: 'tokenURI', params: [id] }));

    const nftsMetadataRaw = await retryAsync(() => batchMulticall<string>(nftContract, callsTokenUri, 3), 200, 3);

    nftsForFetch.forEach((nftId: number, index: number) => {
      const nftMetadataRaw = nftsMetadataRaw[index][0];
      const metadata = decodeMetadata<INftMetadata>(nftMetadataRaw);
      nftsMetadataCache.set(nftId, metadata);
    });
  }

  return nftsMetadataCache;
}

export async function fetchCharactersNftsByIds(nftIds: number[], nftContract: ContractWithAbi<HnO>): Promise<CharacterNft[]> {
  const [cachedMeta, cachedTraits] = await Promise.all([
    fetchMetadata(nftIds, nftContract),
    fetchTraits(nftIds, nftContract)
  ]);

  const nfts = nftIds.map((id) => {
    const metadata = cachedMeta.get(id);
    const nftTraits = cachedTraits.get(id);
    return {
      id,
      ...nftTraits,
      ...metadata
    } as CharacterNft;
  });

  return nfts;
}
