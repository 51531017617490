import React, { FC, useContext } from 'react';
import Button from '@mui/material/Button';

import Modal from '@components/Modal';

import { useAuthMethod } from '@hooks/useAuth';
import { ModalsContext } from '@hooks/useModal';

import useStyles from './styles.module';
import { useAssetsInfo } from '@hooks/useAssetsInfo';
import { formatBNWithCommas } from '@utils/numberFormatters';
import { GEM_TOKEN_DECIMALS } from '@constants/game.constants';
import { Typography } from '@mui/material';

const gem: string = require('@assets/img/GEM_TOKEN.svg').default;
const ham: string = require('@assets/img/Ham.svg').default;

const AccountModal: FC = () => {
  const classes = useStyles();
  const { logOut } = useAuthMethod();
  const { onDismiss } = useContext(ModalsContext);
  const { hamBalance, gemBalance } = useAssetsInfo();
  const handleLogOut = () => {
    logOut();
    onDismiss();
  };

  return (
    <Modal title='My Wallet'>
      <div className={classes.tokenWrap}>
        <div className={classes.wrap}>
          <img src={gem} alt="" />
          <Typography variant="subtitle1" >{formatBNWithCommas(gemBalance, 2, GEM_TOKEN_DECIMALS)}</Typography>
          <div>GEM Available</div>
        </div>
        <div className={classes.wrap}>
          <img src={ham} alt="" />
          <Typography variant="subtitle1" >{formatBNWithCommas(hamBalance, 2, GEM_TOKEN_DECIMALS)}</Typography>
          <div>HAM Available</div>
        </div>
      </div>
      <Button fullWidth onClick={handleLogOut}>
        Log Out
      </Button>
    </Modal>
  );
};

export default AccountModal;
