import React, {FC, useMemo, useState} from 'react';
import clsx from 'clsx';
import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';
import classNames from 'classnames';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import AccountButton from '@components/AccountButton';
import Button from '@components/Button';

import {useAssetsInfo} from '@hooks/useAssetsInfo';
import {formatBNWithCommas} from '@utils/numberFormatters';

import {
  FARM_LINK,
  NAVIGATION,
  NAVIGATION_MARKETPLACE,
  OTHER_LINKS,
  PATH_INDEX,
  TRADE_LINK
} from '@constants/routes.constants';

import ListItemLink from './ListItemLink';

import {ArrowIcon} from '@constants/icons.constants';

import {useStyles} from './styles.module';
import {BUTTON_TYPES} from '@constants/style.constants';

const logo: string = require('@assets/img/headerIcon.svg').default;
const gem: string = require('@assets/img/GEM_TOKEN.svg').default;
const ham: string = require('@assets/img/Ham.svg').default;

interface Props {
  isCasino: boolean
}

const Header: FC<Props> = ({ isCasino }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const matches = useMediaQuery('(min-width:1200px)');
  const { pathname } = useLocation();

  const { gemUsdPrice, hamUsdPrice } = useAssetsInfo();
  const gemUsdPriceValue = useMemo(() => formatBNWithCommas(gemUsdPrice, 3), [gemUsdPrice]);
  const hamUsdPriceValue = useMemo(() => formatBNWithCommas(hamUsdPrice, 3), [hamUsdPrice]);
  const currentNavigation = useMemo(() => (
    pathname.includes('marketplace') ? NAVIGATION_MARKETPLACE : NAVIGATION
  ), [pathname]);

  const openMenu = useMemo(() => (({
      isMore: !!anchorEl && anchorEl?.id === 'positioned-button',
      isTradeGem: !!anchorEl && anchorEl?.id === 'demo-positioned-button',
    })
  ),[anchorEl]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => setOpen(true);

  const handleDrawerClose = () => setOpen(false);

  const handleCloseAutovaults = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="relative" elevation={0} className={classNames(classes.appBar, {[classes.headerCasino]: isCasino })}>
      <Toolbar className={classes.toolbar}>
        {matches ? (
          <>
            <Link to={PATH_INDEX}>
              <img src={logo} alt='headerLogo' />
            </Link>
            {
              currentNavigation.map(item => (
                <Link
                  to={item.path}
                  className={classNames(classes.link, {
                    [classes.active]: pathname === item.path,
                    [classes.linkCasino]: isCasino,
                  })}
                  key={item.name}
                >
                  {item.name}
                </Link>
              ))
            }
            <button
              id="positioned-button"
              className={classNames(classes.link, {[classes.linkCasino]: isCasino})}
              onClick={handleClick}
            >
              More {ArrowIcon}
            </button>
            <Menu
              id="positioned-menu"
              anchorEl={anchorEl}
              open={openMenu.isMore}
              className={classes.menu}
              onClose={handleCloseAutovaults}
            >
              {
                OTHER_LINKS.map(item => (
                  <a
                    href={item.path}
                    key={item.name}
                    target="_blank" rel="noreferrer"
                  >
                    <MenuItem onClick={handleCloseAutovaults}>{item.name}</MenuItem>
                  </a>
                ))
              }
            </Menu>
            <Button
              id="demo-positioned-button"
              onClick={handleClick}
              type={isCasino ? BUTTON_TYPES.CASINO : BUTTON_TYPES.PRIMARY}
            >
              Get GEM
            </Button>
            <Menu
              id="demo-positioned-menu"
              anchorEl={anchorEl}
              open={openMenu.isTradeGem}
              className={classes.menu}
              onClose={handleClose}
            >
              <a href={FARM_LINK} target="_blank" rel="noreferrer"><MenuItem onClick={handleClose}>Farm GEM</MenuItem></a>
              <a href={TRADE_LINK} target="_blank" rel="noreferrer"><MenuItem onClick={handleClose}>Trade GEM</MenuItem></a>
            </Menu>
            <div className={classes.tokenPriceWrap}>
              <div className={classNames(classes.tokenPrice, {[classes.casinoTokenPrice]: isCasino })}>
                <img src={gem} alt="" />
                <span>${gemUsdPriceValue}</span>
              </div>
              <div className={classNames(classes.tokenPrice, {[classes.casinoTokenPrice]: isCasino })}>
                <img src={ham} alt="" />
                <span>${hamUsdPriceValue}</span>
              </div>
            </div>
            <AccountButton isCasino={isCasino} />
          </>
        )
          : (
            <>
              <Link to="/">
                <img src={logo} alt='headerLogo' />
              </Link>
              <div className={classes.row}>
                <div className={classes.tokenPriceWrap}>
                  <div className={classNames(classes.tokenPrice, {[classes.casinoTokenPrice]: isCasino })}>
                    <img src={gem} alt="" />
                    <span>${gemUsdPriceValue}</span>
                  </div>
                </div>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(open && classes.hide)}
                >
                  <MenuIcon color="secondary" />
                </IconButton>
              </div>
              <Drawer
                className={classes.drawer}
                onBackdropClick={handleDrawerClose}
                variant="temporary"
                anchor="left"
                open={open}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                <div>
                  <IconButton onClick={handleDrawerClose} >
                    <ChevronRightIcon className={classes.iconClose} />
                  </IconButton>
                </div>
                <Divider style={{ backgroundColor: '#4134B1' }} />
                <List onClick={handleDrawerClose}>
                  {
                    currentNavigation.map(item => (
                      <ListItemLink primary={item.name} to={item.path} key={item.name} />
                    ))
                  }
                  {
                    OTHER_LINKS.map(item => (
                      <a
                        href={item.path}
                        key={item.name}
                        className={classes.mobileLink}
                        target="_blank" rel="noreferrer"
                      >
                        {item.name}
                      </a>
                    ))
                  }
                  <ListItem style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <AccountButton isCasino={isCasino}/>
                  </ListItem>
                </List>
              </Drawer>
            </>
          )
        }
      </Toolbar>
    </AppBar>
  );

};

export default React.memo(Header);
