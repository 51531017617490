import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Providers from '@components/Providers';
import Layout from '@components/Layout';
import Loader from '@components/Loader';

import {
  PATH_INDEX,
  PATH_MINT,
  PATH_HIDEOUT,
  PATH_TREASURE_CHEST,
  PATH_USER_NFTS,
  PATH_CASINO,
  PATH_HIDEOUT_V2,
  // PATH_TEMPLE,
} from '@constants/routes.constants';

const Home = lazy(() => import('@containers/Home'));
const Mint = lazy(() => import('@containers/Mint'));
const Hideout = lazy(() => import('@containers/Hideout'));
const HideoutV2 = lazy(() => import('@containers/HideoutV2'));
const TreasureChest = lazy(() => import('@containers/TreasureChest'));
// const Temple = lazy(() => import('@containers/Temple'));
const UserNfts = lazy(() => import('@containers/UserNfts'));
const HamsterCasino = lazy(() => import('@containers/HamsterCasino'));

const App = () => {
  return (
    <BrowserRouter>
      <Providers>
        <Layout>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route exact path={PATH_INDEX}>
                <Home />
              </Route>
              <Route path={PATH_MINT}>
                <Mint />
              </Route>
              <Route path={PATH_USER_NFTS}>
                <UserNfts />
              </Route>
              <Route path={PATH_HIDEOUT}>
                <Hideout />
              </Route>
              <Route path={PATH_HIDEOUT_V2}>
                <HideoutV2 />
              </Route>
              <Route path={PATH_TREASURE_CHEST}>
                <TreasureChest />
              </Route>
              <Route path={PATH_CASINO}>
                <HamsterCasino />
              </Route>
              {/* <Route path={PATH_TEMPLE}>
                <Temple />
              </Route>*/}
              <Route path="*">
                <div>Not match route</div>
              </Route>
            </Switch>
          </Suspense >
        </Layout>
      </Providers>
    </BrowserRouter>
  );
};

export default App;
