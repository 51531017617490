import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    button: {
        fontFamily: 'Poppins',
        border: 'none',
        textDecoration: 'none',
        cursor: 'pointer',
        borderRadius: '8px',
        fontWeight: 'normal',
    },
    large: {
        padding: '10px 24px',
        fontSize: '18px',
    },
    medium: {
        padding: '8px 20px',
        fontSize: '16px',
    },
    small: {
        padding: '6px 16px',
        fontSize: '14px',
    },
    primary: {
        background: 'transparent',
        color: '#4134B1',
        border: '1px solid #4134B1',

        '&:hover': {
            background: '#4134B1',
            color: '#FFFFFF',
        },

        '&:disabled': {
            pointerEvents: 'none',
            opacity: '0.35',
        },

        '&:active': {
            opacity: '0.7',
        },
    },
    casino: {
        background: 'transparent',
        color: '#ffffff',
        border: '1px solid #ffffff',

        '&:hover': {
            background: '#4134B1',
            color: '#FFFFFF',
        },

        '&:disabled': {
            pointerEvents: 'none',
            opacity: '0.35',
        },

        '&:active': {
            opacity: '0.7',
        },
    },
    secondary: {
        background: '#FFFFFF',
        color: '#4134B1',

        '&:hover': {
            background: '#F3F6FF',
        },

        '&:disabled': {
            pointerEvents: 'none',
            opacity: '0.35',
        },

        '&:active': {
            opacity: '0.7',
        },
    },
    default: {
        background: 'transparent',
        color: 'rgba(255, 255, 255, 0.87)',
        border: '1px solid rgba(255, 255, 255, 0.87)',
    },
    approval: {
        color: '#FFFFFF',
        background: '#4134B1',

        '&:hover': {
            boxShadow: '0px 4.8px 14.4px rgba(65, 52, 177, 0.4), 0px 25.6px 57.6px rgba(65, 52, 177, 0.2)',
        },

        '&:disabled': {
            pointerEvents: 'none',
            opacity: '0.35',
        },

        '&:active': {
            opacity: '0.7',
        },
    },
    metamask: {
        background: 'rgba(198, 153, 255, 0.15)',
        color: '#FF9E68',
        borderRadius: '100px',
        border: 'none',

        '& img': {
            marginRight: '10px',
        },

        '&:hover': {
            background: 'rgba(198, 153, 255, 0.15)',
        },

        '&:disabled': {
            pointerEvents: 'none',
            opacity: '0.35',
        },

        '&:active': {
            opacity: '0.7',
        },
    },
    wrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loader: {
        marginRight: '11px',
        width: '19px !important',
        height: '19px !important',
    },
    text: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loaderWrap: {
        background: '#2E2E2E',
        color: 'rgba(255, 255, 255, 0.5)',

        '&:disabled': {
            pointerEvents: 'none',
            opacity: '1',
        },

        '&:hover': {
            background: '#2E2E2E',
            color: 'rgba(255, 255, 255, 0.5)',
        },
    },
    error: {
        background: 'transparent',
        color: '#E03838',
        border: '1px solid #E03838',

        '&:disabled': {
            pointerEvents: 'none',
            opacity: '1',
        },

        '&:hover': {
            background: 'transparent',
            color: '#E03838',
            border: '1px solid #E03838',
        },

    },
}));
