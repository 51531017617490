export const PATH_INDEX = '/';
export const PATH_MINT = '/mint';
export const PATH_USER_NFTS = '/nfts';
export const PATH_HIDEOUT = '/hideout';
export const PATH_HIDEOUT_V2 = '/hideout-v2';
export const PATH_TREASURE_CHEST = '/treasure-chest';
export const PATH_TEMPLE = '/temple';
export const PATH_MARKETPLACE = '/marketplace';

export const PATH_CASINO = '/casino';
export const PATH_CASINO_DASHBOARD = '/casino/dashboard';
export const PATH_COINTOSS = '/casino/cointoss';
export const PATH_DICE = '/casino/dice';

export const PATH_MARKETPLACE_AUCTION = '/marketplace/auction';
export const PATH_MARKETPLACE_ARCHIVE = '/marketplace/archive';

export const TRADE_LINK = 'https://spookyswap.finance/swap?outputCurrency=0x42e270Af1FeA762fCFCB65CDB9e3eFFEb2301533';
export const FARM_LINK = 'https://hamster.money/farms/GemFTMLPGemRewardPool';

export const NAVIGATION = [
  {
    name: 'Home',
    path: PATH_INDEX,
  },
  {
    name: 'Mint',
    path: PATH_MINT,
  },
  {
    name: 'Your NFTs',
    path: PATH_USER_NFTS,
  },
  {
    name: 'Hideout',
    path: PATH_HIDEOUT,
  },
  {
    name: 'Hideout 2.0',
    path: PATH_HIDEOUT_V2,
  },
  {
    name: 'Treasure Chest',
    path: PATH_TREASURE_CHEST,
  },
  {
    name: 'Hamster Casino',
    path: PATH_CASINO_DASHBOARD,
  },
  /*{
    name: 'Temple',
    path: PATH_TEMPLE,
  },*/
  /*{
    name: 'Marketplace',
    path: PATH_MARKETPLACE_AUCTION,
  },*/
];

export const OTHER_LINKS = [
  {
    name: 'Hamster Money',
    path: 'https://hamster.money/',
  },
  {
    name: 'Docs',
    path: 'https://docs.hamster.money/hamsters-and-owls/hamsters-and-owls',
  },
  /*{
    name: 'Trade Gem',
    path: 'https://spookyswap.finance/swap?outputCurrency=0x42e270Af1FeA762fCFCB65CDB9e3eFFEb2301533&inputCurrency=0x20ac818b34a60117e12fff5be6abbef68bf32f6d',
  },*/
];

export const NAVIGATION_MARKETPLACE = [
  {
    name: 'Active Auctions',
    path: PATH_MARKETPLACE_AUCTION,
  },
  {
    name: 'Archive',
    path: PATH_MARKETPLACE_ARCHIVE,
  },
  {
    name: 'Docs',
    path: '',
  },
  {
    name: 'Back to the game',
    path: PATH_INDEX,
  },
];
