import React,  {FC, useContext } from 'react';
import { Typography } from '@mui/material';
import classNames from 'classnames';

import { CloseIcon } from '@constants/icons.constants';

import { ModalsContext } from '@hooks/useModal';

import useStyles from './styles.module';


interface Props {
  title: string
  className?: string
}

const Modal: FC<Props> = ({ title, className, children}) => {
  const classes = useStyles();
  const { onDismiss } = useContext(ModalsContext);
  return (
    <div className={classes.container}>
      <div className={classes.modal}>
        <div className={classes.card}>
          <div className={classes.content}>
            <div className={classNames(classes.title, className)}>
              <Typography fontSize={24}>{title}</Typography>
              <button className={classes.button} onClick={onDismiss}>{CloseIcon}</button>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
