import React, { FC } from 'react';
import classNames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';

import { BUTTON_SIZES, BUTTON_TYPES } from '@constants/style.constants';

import { useStyles } from './styles.module';

interface Props {
  className?: string
  disabled?: boolean
  size?: any
  type?: string
  loader?: boolean
  error?: string
  onClick?: (e: any) => void
  style?: any,
  id?: string
}

const Button: FC<Props> = ({ className, disabled, children,
  size, type, onClick, loader, error, style, id
}) => {
  const classes = useStyles();

  return (
    <div className={className}>
      <button
        disabled={disabled || loader || !!error}
        onClick={onClick}
        id={id}
        className={classNames(classes.button, {
          // @ts-ignore
          [classes[size]]: size,
          // @ts-ignore
          [classes[type]]: type,
          [classes.loaderWrap]: loader,
          [classes.error]: error,
        })}
        style={style}
      >
        <div className={classes.text}>
          {loader && <CircularProgress className={classes.loader} />}
          <div className={classes.wrap}>{error || children}</div>
        </div>
      </button>
    </div>
  );
};

Button.defaultProps = {
  className: '',
  disabled: false,
  size: BUTTON_SIZES.L,
  type: BUTTON_TYPES.PRIMARY,
  loader: false,
  error: '',
  style: {},
};

export default Button;
