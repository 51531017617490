import { delay } from './delay';

export const retryAsync = async (cb: (...args: any) => Promise<any>, delayMs: number, retriesCount: number, currentRetryCounter = 0) => {
  try {
    return await cb();
  } catch (e) {
    if (currentRetryCounter < retriesCount) {
      await delay(delayMs);
      return retryAsync(cb, delayMs, retriesCount, currentRetryCounter++);
    } else {
      throw e;
    }
  }
};