//Your theme for the new stuff using material UI has been copied here so it doesn't conflict
import { createTheme } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    text: {
      primary: '#161616',
      secondary: '#9F9EB2',
      disabled: 'rgba(255, 255, 255, 0.5)',
    },
    common: {
      black: '#161616',
      white: '#FFFFFF',
    },
    background: {
      default: '#F3F6FF',
      paper: '#4134B1',
    },
    primary: {
      main: '#F3F6FF',
      contrastText: '#4134B1',
    },
    secondary: {
      main: '#4134B1',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#F46868',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FF9E68',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#9ABCFF',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#99E36C',
      contrastText: '#FFFFFF',
    },
    action: {
      disabledBackground: '#CDCDCD',
      active: '#000',
      hover: '#000',
    },
    grey: {
      100: '#FFFFFF',
      300: '#F0F0F0',
      500: '#CACACA',
      700: '#9F9F9F',
      800: '#4D4D4D',
      900: '#000000',
    },
  },
  typography: {
    htmlFontSize: 14,
    fontSize: 14,
    fontFamily: 'Poppins, sans-serif',
    h1: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      fontSize: 50,
    },
    h2: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
      fontSize: 40,
    },
    h3: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
      fontSize: 50,
    },
    h4: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
      fontSize: 24,
    },
    h5: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      fontSize: 24,
    },
    subtitle1: {
      fontFamily: 'Rubik, sans-serif',
      fontWeight: 400,
      fontSize: 24,
    },
    subtitle2: {
      fontFamily: 'Rubik, sans-serif',
      fontWeight: 400,
      fontSize: 20,
    },
    body1: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 400,
      fontSize: 20,
    },
  }
});


export default theme;
