import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    minHeight: '100vh'
  },
  containerCasino: {
    backgroundColor: '#1F1632',
  },
  ovlImg: {
    position: 'absolute',
    left: '0',
    top: '85px',
  },
  hamsterImg: {
    position: 'absolute',
    right: '0',
    top: '140px',
  },
}));
