import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: any) => ({
  appBar: {
    backgroundColor: theme.palette.background.default,
    padding: '10px',
    marginBottom: '3rem',
    position: 'relative',
    zIndex: 1000,
  },
  headerCasino: {
    backgroundColor: 'transparent !important',
  },
  toolbar: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  link: {
    color: '#161616',
    fontSize: '14px',
    fontFamily: 'Poppins',
    'font-weight': '500',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',

    padding: '12px 16px',
    '&:hover': {
      color: theme.palette.common.white,
      background: theme.palette.background.paper,
      borderRadius: '8px',
      '& svg': {
        marginLeft: '5px',
        '& path': {
          stroke: '#ffffff !important',
        }
      }
    },
    '& svg': {
      marginLeft: '5px',
      '& path': {
        stroke: '#161616 !important',
      }
    },
  },
  linkCasino: {
    color: '#ffffff',
    '& svg': {
      marginLeft: '5px',
      '& path': {
        stroke: '#ffffff !important',
      }
    },
  },
  active: {
    color: theme.palette.common.white,
    background: theme.palette.background.paper,
    borderRadius: '8px',
  },
  tokenPrice: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',

    '& span': {
      color: theme.palette.secondary.main,
      fontSize: '18px',
      marginLeft: '12px',
    },
    '& img': {
      height: '35px',
      width: '35px',
    }
  },
  casinoTokenPrice: {
    '& span': {
      color: '#ffffff',
    },
  },
  tokenPriceWrap: {
    display: 'flex',
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: '#f3f6ff',
    width: 240,
  },
  hide: {
    display: 'none',
  },
  iconClose: {
    color: '#f3f6ff',
  },
  mobileLink: {
    margin: '0',
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '1.5',
    display: 'block',
    color: '#FFFFFF',
    padding: '10px 20px',
  },
  menu: {

    '& .MuiPaper-root': {
      background: '#FFFFFF',
      borderRadius: '8px',
      border: '1px solid #4134B1',
    },
    '& .MuiMenuItem-root': {
      color: '#4134B1',
      fontSize: '18px',
      '&:hover': {
        background: '#4134B1',
        color: '#FFFFFF',
      }

    },
  },
  row: {
    display: 'flex',
    gap: '30px',
  }
}));
