import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  connectButton: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',

    '& img': {
      width: '40px',
      height: '40px',
      marginRight: '1rem',
    }
  }

}));

export default useStyles;
