import { CHAIN_ID } from '@constants/chain.constants';

const CONTRACT_ADDRESSES: { [key in CHAIN_ID]: { [key: string]: string } } = {
  [CHAIN_ID.MAINNET]: {
    GEM: '0x42e270Af1FeA762fCFCB65CDB9e3eFFEb2301533',
    Hideout: '0x12C55A8e0C738091C6f41f6F5b4fF7dD41eD9a73',
    Temple: '0xFD68eFB0f75D0D83e26892baEd4d109D86185611',
    Traits: '0x1680b8025c23449A4a7aed7DF93cF5592e8bb2e3',
    HnO: '0xFE9F8c2b135012184d41DEdAE0c3f7eE8eF5eB0a',
    HnOGameCR: '0xe6e00EB5a1474207845B4BA73727C48F86Fdd7CA',
    Randomizer: '0x751251C9600901d7A3161B2ae945C9197A51EcDa',
    CoinTossGem: '0xAa12f51a03B3Fd6689D7f7C573ad0FcD175359D4',
    CoinTossHam: '0x51a50813DFfBCC6Eb4eFECad2E3aEb64Fca94C38',
    CoinTossSlurp: '0x9b7c88B3cE2B61809de161f9C54257523cC12c3b',
    Multicall: '0xf37dA3428F0C8191149D553FBb218FA8a1Ab2f0f',
    HAM: '0x20ac818b34a60117e12fff5be6abbef68bf32f6d',
    SLURP: '0x26AeB3441428eeAf7D653e9A5B455c57Dd8d8EeB',
    GemHamLp: '0x8d0a916bd1d2255ee579e8035d2a957284a2a34a',
    HamWftmLp: '0x8D1963111AA50a9ae9F8ab5006C204cc46bbF4eD',
    WftmUsdc: '0x2b4C76d0dc16BE1C31D4C1DC53bF9B45987Fc75c',
    DiceHam: '0xd0cE98E7982774136034ce0B22Ad8aECA66109Ff',
    DiceGem: '0x19Ddaa079082bF22913860298f1DE3f9696c2662',
    HideoutV2: '0x2d22402a83F4416BF26E5e2fAC10A053194f759d',
  },
  [CHAIN_ID.TESTNET]: {
    GEM: '0xb2231d07A9ac6516C50a62f7973D8275F3c09011',
    Hideout: '0x3f469B87d8B23C2dD72f7d90603e4182db38B2F1',
    Temple: '0x074A7d468d7c41C80D1031fc0358d5Ece33DF37A',
    Traits: '0xD55250E75f00850ec495D521807c5AA402506Aff',
    HnO: '0x76aeE2065825f8C9aA8baa23Fa8F8528E47197Be',
    HnOGameCR: '0x79D8cF3D937Fa2B333E5a1De9C4DC5d4c009FE62',
    Randomizer: '0x79c5091D437b4DA9f23D13cb36Af3d252d273077',
    CoinTossGem: '0x6389ff6781b7d4f08e5d67a8e7da0bf505faf39b',
    CoinTossHam: '0x6389ff6781b7d4f08e5d67a8e7da0bf505faf39b',
    Multicall: '0x3743F5A0908F1FF35B7a7377b673B28D3492451E',
    HAM: '0x9AacF644308a4247E808DD7B28329d160adBEa76',
    WFTM: '0x8b0C4d22e4D4Adaab9a9a62C16F6B60A885DAFd1',
    SLURP: '0x26AeB3441428eeAf7D653e9A5B455c57Dd8d8EeB',
    GemHamLp: '0x8d0a916bd1d2255ee579e8035d2a957284a2a34a',
    HamWftmLp: '0x8D1963111AA50a9ae9F8ab5006C204cc46bbF4eD',
    WftmUsdc: '0x2b4C76d0dc16BE1C31D4C1DC53bF9B45987Fc75c',
    Dice: '0xa019D46AabBBA6d1343b963e3869e342b09979Fa',
    HideoutV2: '0x4331fA36961f28BDA32BC9963300D781A092be8A',
  },
  [CHAIN_ID.BSC_TESTNET]: {
    GEM: '0x8eC9BeF17d286d151D88b2bBFE2DD7C49ff953Fc',
    Hideout: '0x0F537aC59ad478B1eC018D9EFFE164e0afa4cA86',
    Temple: '0xBf7ECe5c58669191b48E8081c9321A7A6083C18f',
    Traits: '0x52921Ad1E587538190fc8074617D6E7e7C86C83D',
    HnO: '0xC4AEeE9971E4dFa97F8C351F26b50AE7cEaB754b',
    HnOGameCR: '0xe899734e2F3cCe8FCc19d87EdCFFB58A3C11D441',
    Randomizer: '0x38693A4Aae1f5f31e88aE839c07835d79bfA7D48',
    Multicall: '0x2AE9C0F30A1815EB54A9716Bf188f26023a6Db13'
  },
};

export default CONTRACT_ADDRESSES;
