import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme:any) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  },
  wrap: {
    gap: '10px',
    background: theme.palette.background.paper,
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  button: {
    marginTop: '30px',
  }
}));

export default useStyles;
