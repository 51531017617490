import { StaticJsonRpcProvider, JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import getRpcUrl from '@utils/getRpcUrl';

const RPC_URL = getRpcUrl();

export const simpleRpcProvider = new StaticJsonRpcProvider(RPC_URL);


export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
    return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
    return account ? getSigner(library, account) : library;
}