import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

import { CURRENT_CHAIN_ID } from '@constants/chain.constants';
import getNodeUrl from '@utils/getRpcUrl';

export const injected = new InjectedConnector({});

export const walletConnect = new WalletConnectConnector({
  rpc: { [CURRENT_CHAIN_ID]: getNodeUrl() },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
});
