import useAsyncEffect from 'use-async-effect';
import BigNumber from 'bignumber.js';
import React, { useState, useEffect, useCallback } from 'react';
import useActiveWeb3React from '@hooks/useActiveWeb3React';
import { useERC20Contract, useGEMContract, useHideoutContract, useHnOContract, useLPContract } from '@hooks/useContract';
import { toBN } from '@utils/numberFormatters';
import { BALANCES_REFRESH_INTERVAL, PRICES_REFRESH_INTERVAL } from '@constants/game.constants';
import { getGemHamLpAddress, getHamAddress, getHamWftmLpAddress, getSlurpAddress, getWftmUsdcLpAddress } from '@utils/addressHelpers';
import { fetchCachedMetadata, fetchCachedTraits } from '@utils/fetchNfts';

interface IProps {
  gemBalance: BigNumber,
  hamBalance: BigNumber,
  slurpBalance: BigNumber,
  gemUsdPrice: BigNumber,
  hamUsdPrice: BigNumber,
  updateBalances: () => Promise<void>,
}

const initState: IProps = {
  gemBalance: toBN(0),
  hamBalance: toBN(0),
  slurpBalance: toBN(0),
  gemUsdPrice: toBN(0),
  hamUsdPrice: toBN(0),
  updateBalances: () => Promise.resolve(),
};

interface IBalancesState {
  gemBalance: BigNumber,
  hamBalance: BigNumber,
  slurpBalance: BigNumber
}

const initBalancesState: IBalancesState = {
  gemBalance: toBN(0),
  hamBalance: toBN(0),
  slurpBalance: toBN(0),
};


export const AssetsInfoContext = React.createContext<IProps>(initState);

export const AssetsInfoContextProvider: React.FC = ({ children }) => {
  const { account } = useActiveWeb3React();
  const [balances, setBalances] = useState<IBalancesState>(initBalancesState);
  const [gemUsdPrice, setGemUsdPrice] = useState(toBN(0));
  const [hamUsdPrice, setHamUsdPrice] = useState(toBN(0));

  const contractHideout = useHideoutContract();
  const contractHnO = useHnOContract();
  const gemContract = useGEMContract();

  const gemHamLpContract = useLPContract(getGemHamLpAddress());
  const hamWftmLpContract = useLPContract(getHamWftmLpAddress());
  const wftmUsdcLpContract = useLPContract(getWftmUsdcLpAddress());
  const hamContract = useERC20Contract(getHamAddress());
  const slurpContract = useERC20Contract(getSlurpAddress());

  const updateBalances = useCallback(async () => {
    const [gemBalanceRaw, hamBalanceRaw, slurpBalanceRaw] = await Promise.all([
      gemContract.balanceOf(account),
      hamContract.balanceOf(account),
      slurpContract.balanceOf(account),
    ]);

    const gemBalance = toBN(gemBalanceRaw);
    const hamBalance = toBN(hamBalanceRaw);
    const slurpBalance = toBN(slurpBalanceRaw);
    setBalances({
      gemBalance,
      hamBalance,
      slurpBalance,
    });
  }, [account, gemContract, hamContract, slurpContract]);

  useEffect(() => {
    updateBalances().catch((err) => console.error(`Failed to fetch token balance: ${err.stack}`));
    const refreshInterval = setInterval(updateBalances, BALANCES_REFRESH_INTERVAL);
    return () => clearInterval(refreshInterval);
  }, [updateBalances]);

  useAsyncEffect(async () => {
    const uppdatePrice = async () => {
      const [gemHamLpReserves, hamWftmLpReserves, wftmUsdcLpReserves] = await Promise.all([
        gemHamLpContract.getReserves(),
        hamWftmLpContract.getReserves(),
        wftmUsdcLpContract.getReserves(),
      ]);
      const gemHamPriceBN = toBN(gemHamLpReserves._reserve0).div(toBN(gemHamLpReserves._reserve1));
      const hamWftmPriceBN = toBN(hamWftmLpReserves._reserve1).div(toBN(hamWftmLpReserves._reserve0));
      const usdcftmPriceBN = toBN(wftmUsdcLpReserves._reserve0, 6).div(toBN(wftmUsdcLpReserves._reserve1, 18));
      const gemUsdPrice = gemHamPriceBN.times(hamWftmPriceBN).times(usdcftmPriceBN);
      const hamUsdPrice = hamWftmPriceBN.times(usdcftmPriceBN);
      setHamUsdPrice(hamUsdPrice);
      setGemUsdPrice(gemUsdPrice);
    };

    if (process.env.REACT_APP_ENV !== 'production') return;
    uppdatePrice().catch((err) => console.error(`Failed to fetch GEM price: ${err.stack}`));
    const refreshInterval = setInterval(uppdatePrice, PRICES_REFRESH_INTERVAL);
    return () => clearInterval(refreshInterval);
  }, [gemHamLpContract, hamWftmLpContract, wftmUsdcLpContract]);

  useEffect(() => {
    (async () => {
      try {
        if (!account) {
          return;
        }
        const stakedNftIdsRaw = await contractHideout.accountStakedTokenIds(account, 0, 1000);
        if (stakedNftIdsRaw.length) {
          const stakedNftIds = stakedNftIdsRaw.map((idBN) => idBN.toNumber());
          await Promise.all([
            fetchCachedMetadata(stakedNftIds, contractHnO),
            fetchCachedTraits(stakedNftIds, contractHnO)
          ]);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [account, contractHnO, contractHideout]);


  return <AssetsInfoContext.Provider value={{ ...balances, gemUsdPrice, hamUsdPrice, updateBalances }}>{children}</AssetsInfoContext.Provider>;
};
