import { toBN } from '@utils/numberFormatters';
import { StuffNft } from 'types';
// import { AIR_RUNE_IMAGE, EARTH_RUNE_IMAGE, FIRE_RUNE_IMAGE, WATER_RUNE_IMAGE, CHEST_IMAGE } from './images.constants';
const AirRuneSvg: string = require('@assets/img/stuff/air_rune.svg').default;
const EarthRuneSvg: string = require('@assets/img/stuff/earth_rune.svg').default;
const FireRuneSvg: string = require('@assets/img/stuff/fire_rune.svg').default;
const WaterRuneSvg: string = require('@assets/img/stuff/water_rune.svg').default;
const TreasureChestSvg: string = require('@assets/img/stuff/treasure_chest.svg').default;

export enum STUFF_NFT_TYPES {
  AIR_RUNE = 1,
  EARTH_RUNE = 2,
  FIRE_RUNE = 3,
  WATER_RUNE = 4,
  CHEST = 5,
}
export const ALTER_NFT_IDS = [
  STUFF_NFT_TYPES.AIR_RUNE,
  STUFF_NFT_TYPES.EARTH_RUNE,
  STUFF_NFT_TYPES.FIRE_RUNE,
  STUFF_NFT_TYPES.WATER_RUNE,
];

export const RAW_ALTER_NFTS: StuffNft[] = [
  {
    id: 1,
    type: STUFF_NFT_TYPES.AIR_RUNE,
    name: 'Air Rune',
    image: AirRuneSvg,
  },
  {
    id: 2,
    type: STUFF_NFT_TYPES.EARTH_RUNE,
    name: 'Earth Rune',
    image: EarthRuneSvg
  },
  {
    id: 3,
    type: STUFF_NFT_TYPES.FIRE_RUNE,
    name: 'Fire Rune',
    image: FireRuneSvg
  },
  {
    id: 4,
    type: STUFF_NFT_TYPES.WATER_RUNE,
    name: 'Water Rune',
    image: WaterRuneSvg
  },
  {
    id: 5,
    type: STUFF_NFT_TYPES.CHEST,
    name: 'Chest',
    image: TreasureChestSvg
  }
].map((item) => ({ ...item, balance: toBN(0) }));


export enum CHARACTERS_NFT_TYPES {
  HAMSTER = 1,
  OWL = 2,
}
export const CHARACTERS_NFT_IDS = [
  CHARACTERS_NFT_TYPES.HAMSTER,
  CHARACTERS_NFT_TYPES.OWL,
];

export const MIN_TO_CLAIM_HAMSTER_SEC = process.env.REACT_APP_ENV === 'production' ? 172800 : 2;
export const GEM_TOKEN_DECIMALS = 18;
export const MAX_OWL_RANK = 8;
export const GEM_HIDEOUT_CLAIM_TAX_PERCENTAGE = 0.2;
export const MAX_MINT_COMMIT_COUNT = 10;
export const MAX_CHEST_BUY_COUNT = 100;
export const BALANCES_REFRESH_INTERVAL = 20 * 1000;
export const STAKED_NFTS_REFRESH_INTERVAL = 20 * 1000;
export const ROUND_CHECKING_INTERVAL = 3.5 * 1000;
export const PRICES_REFRESH_INTERVAL = 60 * 1000;

// If user selects more than ~300 there is Gas exceeds error appears
export const MAX_SELECTED_STAKED_NFTS_FOR_CLAIM = 300;
export const MAX_SELECTED_STAKED_NFTS_FOR_UNSTAKE = 50;
export const MAX_SELECTED_STAKED_NFTS_FOR_STAKE = 40;

export enum GENS {
  PREMINT = 'PREMINT',
  GEN0 = 'Gen 0',
  GEN1 = 'Gen 1',
  GEN2 = 'Gen 2',
  GEN3 = 'Gen 3',
  GEN4 = 'Gen 4',
  GEN5 = 'Gen 5',
}

export enum TOKEN_TICKERS {
  HAM = 'HAM',
  GEM = 'GEM',
  SLURP = 'SLURP',
}
