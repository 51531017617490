import React, { forwardRef, useMemo, FC } from 'react';
import { Link } from 'react-router-dom';
import { ListItem, ListItemText } from '@mui/material';

interface Props {
  primary: string
  to: string
}

const ListItemLink: FC<Props> = ({ primary, to }) => {
  const CustomLink = useMemo(
    // eslint-disable-next-line react/display-name
    () => forwardRef((linkProps, ref: any) => <Link ref={ref} to={to} {...linkProps}/>),
    [to],
  );

  return (
    <li>
      <ListItem button component={CustomLink}>
        <ListItemText primary={primary} color='secondary' style={{ color: '#ffffff'}}/>
      </ListItem>
    </li>
  );
};

export default ListItemLink;
