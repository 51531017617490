import sample from 'lodash/sample';
import { CHAIN_ID, PUBLIC_RPC_1, PUBLIC_RPC_2, PUBLIC_TESTNET_RPC_2, CURRENT_CHAIN_ID } from '@constants/chain.constants';

// Array of available nodes to connect to
export const nodes = {
  [CHAIN_ID.MAINNET]: [PUBLIC_RPC_1, PUBLIC_RPC_2],
  [CHAIN_ID.TESTNET]: [PUBLIC_TESTNET_RPC_2]
};

const getNodeUrl = () => {
  return sample(nodes[CURRENT_CHAIN_ID]) || PUBLIC_RPC_1;
};

export default getNodeUrl;
