import React, { FC } from 'react';
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';

import { useStyles } from './styles.module';
import classNames from 'classnames';

const GithubImage: string = require('@assets/img/github.svg').default;
const TwitterImage: string = require('@assets/img/twitter.svg').default;
const DiscordImage: string = require('@assets/img/discord.svg').default;
const footerOvl: string = require('@assets/img/footerOvl.svg').default;

interface Props {
  isCasino: boolean
}

const Footer: FC<Props> = ({ isCasino }) => {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:900px)');


  return (
    <footer className={classNames(classes.footer, {[classes.footerCasino]: isCasino})}>
      <Container maxWidth="lg">
        <Grid container className={classes.footerWrap}>
          <Grid item lg={6} xs={12} style={{ textAlign: 'left' }}>
            {matches && !isCasino && <img src={footerOvl} className={classes.footerOvl} alt="" />}
            <a
              href="https://github.com/hamster-money"
              rel="noopener noreferrer"
              target="_blank"
              className={classes.link}
            >
              <img alt="github" src={GithubImage} className={classes.img} />
            </a>
            <a href="https://twitter.com/HamstersOfFTM" rel="noopener noreferrer" target="_blank" className={classes.link}>
              <img alt="discord" src={TwitterImage} className={classes.img} />
            </a>
            <a href="https://discord.com/invite/4VFAtTcnBt" rel="noopener noreferrer" target="_blank" className={classes.link}>
              <img alt="discord" src={DiscordImage} className={classes.img} />
            </a>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Typography
              variant="body2"
              color="secondary"
              align="right"
              className={classNames({[classes.casinoColor] : isCasino})}
            >
              {'© '} {new Date().getFullYear()}{' '}
              Hamster Money
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );

};
export default React.memo(Footer);
