import { makeStyles } from '@mui/styles';
import theme from '../../theme';

const useStyles = makeStyles(() => ({
  container: {
    boxSizing: 'border-box',
    width: '100%',
    margin: '0 auto',
    maxWidth: '800px',
  },
  modal: {
    borderRadius: '12px',
    position: 'relative',
  },
  card: {
    fontFamily: 'Poppins',
    background: theme.palette.background.paper,
    color: `${theme.palette.common.white} !important`,
    borderRadius: '8px',
    boxShadow: '0px 4.8px 14.4px rgba(65, 52, 177, 0.4), 0px 25.6px 57.6px rgba(65, 52, 177, 0.2)',
  },
  content: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    padding: '24px',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  button: {
    position: 'absolute',
    right: '24px',
    background: 'transparent',
    cursor: 'pointer',
    border: 'none',
  }
}));
export default useStyles;
