import { Interface } from '@ethersproject/abi';
import _ from 'lodash';

import { getMulticallContract } from '@utils/contractHelpers';
import { ContractWithAbi } from 'types';

export interface Call {
  name: string // Function name on the contract (example: balanceOf)
  params?: any[] // Function params
}

export interface ContractCall<C extends ContractWithAbi = any> extends Call {
  contract: C
}


export interface MulticallOptions {
  requireSuccess?: boolean
}


export async function batchMulticall<T>(contract: ContractWithAbi, calls: Call[], batchSize = 5): Promise<T[][]> {
  const chunks = _.chunk(calls, batchSize);
  const chunkResults = await Promise.all(chunks.map((chunkCalls) => multicall<T>(contract, chunkCalls)));
  const result = chunkResults.reduce((acc, item) => [...acc, ...item], []);
  return result;
}

export async function multicall<T, C extends ContractWithAbi = any>(contract: C, calls: Call[]): Promise<T[][]> {
  const multi = getMulticallContract((contract.provider as any).getSigner());
  const itf = new Interface(contract.abi);
  const calldata = calls.map((call) => ({
    target: contract.address.toLowerCase(),
    callData: itf.encodeFunctionData(call.name, call.params),
  }));

  const { returnData } = await multi.aggregate(calldata);
  const res = returnData.map((call, i) => itf.decodeFunctionResult(calls[i].name, call));
  return res as any;
}


export async function multicallAny<C extends ContractWithAbi = any>(calls: ContractCall<C>[]): Promise<any[][]> {
  if (!calls.length) { return []; }
  const { provider } = calls[0].contract;
  const multi = getMulticallContract((provider as any).getSigner());

  const callData = calls.map(({ contract, name, params }) => {
    const itf = new Interface(contract.abi);
    return ({
      target: contract.address.toLowerCase(),
      callData: itf.encodeFunctionData(name, params),
    });
  });

  const { returnData } = await multi.aggregate(callData);

  const res = returnData.map((call, i) => {
    const originCall = calls[i];
    const itf = new Interface(originCall.contract.abi);
    return itf.decodeFunctionResult(originCall.name, call);
  });

  return res as any;
}
