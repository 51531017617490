import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  footer: {
    position: 'absolute',
    bottom: '0',
    paddingTop: '50px',
    paddingBottom: '50px',
    width: '100%',
    backgroundColor: 'transparent',
    textAlign: 'center',
    height: '1.3rem',
  },
  footerCasino: {
    backgroundColor: 'transparent !important',
  },
  casinoColor: {
    color: '#ffffff !important',
  },
  link: {
    width: '24px',
    height: '24px',
    display: 'inline',
    marginLeft: '20px',
  },
  img: {
    width: '24px',
    height: '24px',
  },
  footerWrap: {
    position: 'relative',
  },
  footerOvl: {
    position: 'absolute',
    right: '250px',
    bottom: '-50px',
  },
}));
