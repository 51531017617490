import _ from 'lodash';
import { delay } from './delay';

export const batchAsync = async <T>(cbs: Promise<T>[], batchSize: number, waitingMs = 2000) => {
  const batches: Promise<T>[][] = _.chunk(cbs, batchSize);
  const results: T[] = [];
  for (const batch of batches) {
    const batchResults = await Promise.all(batch);
    results.push(...batchResults);
    await delay(waitingMs);
  }

  return results;
};
