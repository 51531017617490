/* eslint-disable max-len */
import React from 'react';

export const CloseIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.6" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M15.5303 9.53033C15.8232 9.23744 15.8232 8.76256 15.5303 8.46967C15.2374 8.17678 14.7626 8.17678 14.4697 8.46967L12 10.9393L9.53033 8.46967C9.23744 8.17678 8.76256 8.17678 8.46967 8.46967C8.17678 8.76256 8.17678 9.23744 8.46967 9.53033L10.9393 12L8.46967 14.4697C8.17678 14.7626 8.17678 15.2374 8.46967 15.5303C8.76256 15.8232 9.23744 15.8232 9.53033 15.5303L12 13.0607L14.4697 15.5303C14.7626 15.8232 15.2374 15.8232 15.5303 15.5303C15.8232 15.2374 15.8232 14.7626 15.5303 14.4697L13.0607 12L15.5303 9.53033Z" fill="white" fillOpacity="0.87"/>
  </svg>
);

export const AlertTriangle = (
  <svg width="47" height="43" viewBox="0 0 47 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">
      <path d="M21.221 11.1467L9.92767 30C9.69483 30.4033 9.57163 30.8604 9.57032 31.3261C9.56902 31.7917 9.68966 32.2495 9.92024 32.6541C10.1508 33.0586 10.4833 33.3957 10.8846 33.6318C11.2859 33.868 11.7421 33.9949 12.2077 34H34.7943C35.2599 33.9949 35.7161 33.868 36.1174 33.6318C36.5187 33.3957 36.8512 33.0586 37.0818 32.6541C37.3123 32.2495 37.433 31.7917 37.4317 31.3261C37.4304 30.8604 37.3072 30.4033 37.0743 30L25.781 11.1467C25.5433 10.7548 25.2086 10.4309 24.8093 10.206C24.4099 9.98116 23.9593 9.86304 23.501 9.86304C23.0427 9.86304 22.5921 9.98116 22.1927 10.206C21.7934 10.4309 21.4587 10.7548 21.221 11.1467V11.1467Z" stroke="#DAA10E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M23.5 18V23.3333" stroke="#DAA10E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M23.5 28.6667H23.5133" stroke="#DAA10E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <filter id="filter0_d" x="-0.5" y="-2" width="48" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.941176 0 0 0 0 0.72549 0 0 0 0 0.0431373 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

export const CloseToastIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.7">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.1785 10.0003L15.5894 5.58946C15.9152 5.26362 15.9152 4.73696 15.5894 4.41112C15.2635 4.08529 14.7369 4.08529 14.411 4.41112L10.0002 8.82196L5.58938 4.41112C5.26355 4.08529 4.73688 4.08529 4.41105 4.41112C4.08521 4.73696 4.08521 5.26362 4.41105 5.58946L8.82188 10.0003L4.41105 14.4111C4.08521 14.737 4.08521 15.2636 4.41105 15.5895C4.57355 15.752 4.78688 15.8336 5.00021 15.8336C5.21355 15.8336 5.42688 15.752 5.58938 15.5895L10.0002 11.1786L14.411 15.5895C14.5735 15.752 14.7869 15.8336 15.0002 15.8336C15.2135 15.8336 15.4269 15.752 15.5894 15.5895C15.9152 15.2636 15.9152 14.737 15.5894 14.4111L11.1785 10.0003Z" fill="white"/>
    </g>
  </svg>
);

export const SuccessIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M20 10C20 4.477 15.523 0 10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20C15.523 20 20 15.523 20 10ZM14.311 7.87359C14.6039 7.5807 14.6039 7.10582 14.311 6.81293C14.0181 6.52004 13.5432 6.52004 13.2503 6.81293L8.47567 11.5876L6.53033 9.64226C6.23744 9.34937 5.76256 9.34937 5.46967 9.64227C5.17678 9.93516 5.17678 10.41 5.46967 10.7029L7.94534 13.1786C8.23823 13.4715 8.7131 13.4715 9.006 13.1786L14.311 7.87359Z" fill="white"/>
  </svg>
);

export const ErrorIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M20 10C20 4.477 15.523 0 10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20C15.523 20 20 15.523 20 10ZM14.311 7.87359C14.6039 7.5807 14.6039 7.10582 14.311 6.81293C14.0181 6.52004 13.5432 6.52004 13.2503 6.81293L8.47567 11.5876L6.53033 9.64226C6.23744 9.34937 5.76256 9.34937 5.46967 9.64227C5.17678 9.93516 5.17678 10.41 5.46967 10.7029L7.94534 13.1786C8.23823 13.4715 8.7131 13.4715 9.006 13.1786L14.311 7.87359Z" fill="white"/>
  </svg>
);
export const WarningIcon = (
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.06201 0.967108C8.57801 -0.00689179 9.42001 -0.0108918 9.93801 0.967108L17.062 14.4251C17.578 15.3991 17.107 16.1961 16.005 16.1961H1.99501C0.893012 16.1961 0.420012 15.4031 0.938012 14.4251L8.06201 0.967108ZM8.2929 10.7072C8.48044 10.8947 8.73479 11.0001 9.00001 11.0001C9.26523 11.0001 9.51958 10.8947 9.70712 10.7072C9.89465 10.5197 10 10.2653 10 10.0001V5.00011C10 4.73489 9.89465 4.48054 9.70712 4.293C9.51958 4.10546 9.26523 4.00011 9.00001 4.00011C8.73479 4.00011 8.48044 4.10546 8.2929 4.293C8.10537 4.48054 8.00001 4.73489 8.00001 5.00011V10.0001C8.00001 10.2653 8.10537 10.5197 8.2929 10.7072ZM8.2929 13.7072C8.48044 13.8948 8.73479 14.0001 9.00001 14.0001C9.26523 14.0001 9.51958 13.8948 9.70712 13.7072C9.89465 13.5197 10 13.2653 10 13.0001C10 12.7349 9.89465 12.4805 9.70712 12.293C9.51958 12.1055 9.26523 12.0001 9.00001 12.0001C8.73479 12.0001 8.48044 12.1055 8.2929 12.293C8.10537 12.4805 8.00001 12.7349 8.00001 13.0001C8.00001 13.2653 8.10537 13.5197 8.2929 13.7072Z" fill="white"/>
  </svg>
);
export const InfoIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM11 6C11 6.55228 10.5523 7 10 7C9.44771 7 9 6.55228 9 6C9 5.44772 9.44771 5 10 5C10.5523 5 11 5.44772 11 6ZM10 8C10.5523 8 11 8.44771 11 9V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V9C9 8.44771 9.44771 8 10 8Z" fill="white"/>
  </svg>
);

export const ArrowIcon = (
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.00098 1L7.00098 7L13.001 1" stroke="white" strokeOpacity="0.87" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);
