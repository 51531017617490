import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  tokenWrap: {
    display: 'flex',
    justifyContent: 'center',
    gap: '30px',
    marginBottom: '50px',

    '& img': {
      height: '120px',
      width: '120px',
      marginBottom: '15px',
    }
  },
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  countUp: {
    fontSize: 24,
    fontWeight: 700,


  },
}));

export default useStyles;
