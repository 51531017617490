import { CURRENT_CHAIN_ID } from '@constants/chain.constants';
import { TOKEN_TICKERS } from '@constants/game.constants';
import CONTRACT_ADDRESSES from '@contracts/addresses';

export type CoinTossGamebleToken = TOKEN_TICKERS.GEM | TOKEN_TICKERS.HAM | TOKEN_TICKERS.SLURP
export type DiceGamebleToken = TOKEN_TICKERS.GEM | TOKEN_TICKERS.HAM | TOKEN_TICKERS.SLURP

export const getAddress = (contractName: string): string => {
  const addresses = CONTRACT_ADDRESSES[CURRENT_CHAIN_ID];
  const address = addresses[contractName];
  if (!address) {
    throw new Error(`Unknown address for ${contractName} name`);
  }
  return address;
};

export const getGEMAddress = () => {
  return getAddress(TOKEN_TICKERS.GEM);
};

export const getHamAddress = () => {
  return getAddress(TOKEN_TICKERS.HAM);
};

export const getSlurpAddress = () => {
  return getAddress(TOKEN_TICKERS.SLURP);
};

export const getHideoutAddress = () => {
  return getAddress('Hideout');
};

export const getTempleAddress = () => {
  return getAddress('Temple');
};

export const getTraitsAddress = () => {
  return getAddress('Traits');
};

export const getHnOAddress = () => {
  return getAddress('HnO');
};

export const getHnOGameCRAddress = () => {
  return getAddress('HnOGameCR');
};

export const getRandomizerAddress = () => {
  return getAddress('Randomizer');
};

export const getMulticallAddress = () => {
  return getAddress('Multicall');
};

export const getGemHamLpAddress = () => {
  return getAddress('GemHamLp');
};

export const getHamWftmLpAddress = () => {
  return getAddress('HamWftmLp');
};

export const getWftmUsdcLpAddress = () => {
  return getAddress('WftmUsdc');
};

export const getCoinTossAddress = (gamebleToken: CoinTossGamebleToken) => {
  switch (gamebleToken) {
    case TOKEN_TICKERS.GEM: return getAddress('CoinTossGem');
    case TOKEN_TICKERS.HAM: return getAddress('CoinTossHam');
    case TOKEN_TICKERS.SLURP: return getAddress('CoinTossSlurp');
  }
};

export const getDiceAddress = (gamebleToken: DiceGamebleToken) => {
  switch (gamebleToken) {
    case TOKEN_TICKERS.GEM: return getAddress('DiceGem');
    case TOKEN_TICKERS.HAM: return getAddress('DiceHam');
  }
};

export const getERC20Address = (tokenTicker: string) => {
  return getAddress(tokenTicker);
};

export const getHideoutV2Address = () => {
  return getAddress('HideoutV2');
};