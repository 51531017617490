import React, {FC, useMemo} from 'react';

import Button from '@components/Button';
import WalletProviderModal from '@components/modals/WalletProviderModal';
import AccountModal from '@components/modals/AccountModal';

import useActiveWeb3React from '@hooks/useActiveWeb3React';
import {useModal} from '@hooks/useModal';

import {BUTTON_TYPES} from '@constants/style.constants';

interface Props {
  isCasino: boolean
}

const AccountButton: FC<Props> = ({ isCasino }) => {
  const { account } = useActiveWeb3React();
  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal/>);
  const [onPresentAccountModal] = useModal(<AccountModal/>);

  const buttonTypes = useMemo(() => (
    isCasino ? BUTTON_TYPES.CASINO : BUTTON_TYPES.PRIMARY
  ),[isCasino]);

  return (
    <div>
      {!account ? (
        <Button onClick={onPresentWalletProviderModal} type={buttonTypes}>
          Connect
        </Button>
      ) : (
        <Button onClick={onPresentAccountModal} type={buttonTypes}>
          My Wallet
        </Button>
      )}
    </div>
  );

};

export default AccountButton;
