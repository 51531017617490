import { useMemo } from 'react';
import type { Signer } from '@ethersproject/abstract-signer';
import type { Provider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts';

import useActiveWeb3React from '@hooks/useActiveWeb3React';
import { simpleRpcProvider } from '@utils/providers';

import {
  getGEMAddress,
  getHideoutAddress,
  getTempleAddress,
  getTraitsAddress,
  getHnOAddress,
  getHnOGameCRAddress,
  getRandomizerAddress,
  getHideoutV2Address
} from '@utils/addressHelpers';

import {
  GEM,
  Hideout,
  Temple,
  Traits,
  HnO,
  HnOGameCR,
  Randomizer,
  Pair,
  Erc20,
  CoinToss,
  Dice,
  HideoutV2
} from '@contracts/abis/types';

import GEMAbi from '@contracts/abis/GEM.json';
import HideoutAbi from '@contracts/abis/Hideout.json';
import TempleAbi from '@contracts/abis/Temple.json';
import TraitsAbi from '@contracts/abis/Traits.json';
import HnOAbi from '@contracts/abis/HnO.json';
import HnOGameCRAbi from '@contracts/abis/HnOGameCR.json';
import RandomizerAbi from '@contracts/abis/Randomizer.json';
import Erc20Abi from '@contracts/abis/erc20.json';
import PairAbi from '@contracts/abis/Pair.json';
import CoinTossAbi from '@contracts/abis/CoinToss.json';
import DiceAbi from '@contracts/abis/Dice.json';
import HideoutV2Abi from '@contracts/abis/HideoutV2.json';
import { ContractWithAbi } from '../types';

const getContract = <T extends Contract>(abi: any, address: string, signer?: Signer | Provider): ContractWithAbi<T> => {
  const signerOrProvider = signer ?? simpleRpcProvider;
  const contract = new Contract(address, abi, signerOrProvider) as ContractWithAbi<T>;
  contract.abi = abi;
  return contract;
};

export const useGEMContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getContract<GEM>(GEMAbi, getGEMAddress(), library?.getSigner()), [library]);
};

export const useHideoutContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getContract<Hideout>(HideoutAbi, getHideoutAddress(), library?.getSigner()), [library]);
};

export const useTempleContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getContract<Temple>(TempleAbi, getTempleAddress(), library?.getSigner()), [library]);
};

export const useTraitsContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getContract<Traits>(TraitsAbi, getTraitsAddress(), library?.getSigner()), [library]);
};

export const useHnOContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getContract<HnO>(HnOAbi, getHnOAddress(), library?.getSigner()), [library]);
};

export const useHnOGameCRContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getContract<HnOGameCR>(HnOGameCRAbi, getHnOGameCRAddress(), library?.getSigner()), [library]);
};

export const useRandomizerContract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getContract<Randomizer>(RandomizerAbi, getRandomizerAddress(), library?.getSigner()), [library]);
};

export const useCoinTossContract = (address: string) => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getContract<CoinToss>(CoinTossAbi, address, library?.getSigner()), [library, address]);
};

export const useDiceContract = (address: string) => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getContract<Dice>(DiceAbi, address, library?.getSigner()), [library, address]);
};

export const useLPContract = (address: string) => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getContract<Pair>(PairAbi, address, library.getSigner()), [library, address]);
};

export const useERC20Contract = (address: string) => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getContract<Erc20>(Erc20Abi, address, library?.getSigner()), [library, address]);
};


export const useHideoutV2Contract = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getContract<HideoutV2>(HideoutV2Abi, getHideoutV2Address(), library?.getSigner()), [library]);
};
