import type { Signer } from '@ethersproject/abstract-signer';
import type { Provider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts';
import { simpleRpcProvider } from '@utils/providers';

import {
  GEM,
  Hideout,
  Temple,
  Traits,
  HnO,
  HnOGameCR,
  Randomizer,
  Multicall
} from '@contracts/abis/types';

import { getMulticallAddress } from './addressHelpers';

import GEMAbi from '@contracts/abis/GEM.json';
import HideoutAbi from '@contracts/abis/Hideout.json';
import TempleAbi from '@contracts/abis/Temple.json';
import TraitsAbi from '@contracts/abis/Traits.json';
import HnOAbi from '@contracts/abis/HnO.json';
import HnOGameCRAbi from '@contracts/abis/HnOGameCR.json';
import RandomizerAbi from '@contracts/abis/Randomizer.json';
import MulticallAbi from '@contracts/abis/Multicall.json';

const getContract = (abi: any, address: string, signer?: Signer | Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider;
  return new Contract(address, abi, signerOrProvider);
};
export const getGEMContract = (address: string, signer?: Signer | Provider) => {
  return getContract(GEMAbi, address, signer) as GEM;
};

export const getHideoutContract = (address: string, signer?: Signer | Provider) => {
  return getContract(HideoutAbi, address, signer) as Hideout;
};

export const getTempleContract = (address: string, signer?: Signer | Provider) => {
  return getContract(TempleAbi, address, signer) as Temple;
};

export const getTraitsContract = (address: string, signer?: Signer | Provider) => {
  return getContract(TraitsAbi, address, signer) as Traits;
};

export const getHnOContract = (address: string, signer?: Signer | Provider) => {
  return getContract(HnOAbi, address, signer) as HnO;
};

export const getHnOGameCRContract = (address: string, signer?: Signer | Provider) => {
  return getContract(HnOGameCRAbi, address, signer) as HnOGameCR;
};

export const getRandomizerContract = (address: string, signer?: Signer | Provider) => {
  return getContract(RandomizerAbi, address, signer) as Randomizer;
};

export const getMulticallContract = (signer?: Signer | Provider) => {
  return getContract(MulticallAbi, getMulticallAddress(), signer) as Multicall;
};