import React, { FC } from 'react';
import Container from '@mui/material/Container';
import  styled ,{ createGlobalStyle } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import Header from '@components/Layout/Header';
import Footer from '@components/Layout/Footer';
import ConnectWallet from '@components/ConnectWallet';

import useActiveWeb3React from '@hooks/useActiveWeb3React';

import { CURRENT_CHAIN_ID } from '@constants/chain.constants';
import { PATH_INDEX, PATH_CASINO } from '@constants/routes.constants';

import { useStyles } from './styles.module';
import classNames from 'classnames';


const backgroundImg: string = require('@assets/img/starsBg.svg').default;
//const hamsterCasinoImg: string = require('@assets/img/НamsterCasino.svg').default;
const hamsterCasinoImg: string = require('@assets/img/casinoBG.svg').default;
const hamsterBg: string = require('@assets/img/hamsterbg.svg').default;
const ovlBg: string = require('@assets/img/ovlBg.svg').default;


const Layout: FC = ({ children }) => {
  const classes = useStyles();
  const { chainId } = useActiveWeb3React();
  const { pathname } = useLocation();
  const matches = useMediaQuery('(min-width:900px)');

  return (
    <div className={classNames(classes.container, {[classes.containerCasino]: pathname.includes(PATH_CASINO)})}>
      <Header isCasino={pathname.includes(PATH_CASINO)} />
      {
        matches && !pathname.includes(PATH_CASINO) && (
          <>
            <img src={hamsterBg} className={classes.hamsterImg} alt=""/>
            <img src={ovlBg} className={classes.ovlImg} alt=""/>
          </>
        )
      }
      {pathname.includes(PATH_CASINO) && <HamsterCasinoImg />}
      <Container maxWidth="lg" style={{ paddingBottom: '5rem', position: 'relative' }}>
        {!pathname.includes(PATH_CASINO) && <BackgroundImage />}
        {
          pathname === PATH_INDEX ? (
            <>{children}</>
          ) : (
            <>
              {CURRENT_CHAIN_ID === chainId ? (<>{children}</>) : (<ConnectWallet />)}
            </>
          )
        }
      </Container>
      <Footer isCasino={pathname.includes(PATH_CASINO)} />
    </div>
  );
};

const BackgroundImage = createGlobalStyle`
  body {
    background: url(${backgroundImg}) no-repeat !important;
    background-color: #F3F6FF !important;
    background-position: center !important;
  }
`;

/*const HamsterCasinoImg = createGlobalStyle`
  body {
    background-image: url(${hamsterCasinoImg}) no-repeat !important;
    background-position: center !important;
  }
`;*/

const HamsterCasinoImg = styled.div`
  background-image: url(${hamsterCasinoImg}) !important;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center !important;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;



export default Layout;
