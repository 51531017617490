import BigNumber from 'bignumber.js';
import { BigNumber as EthersBigNumber } from '@ethersproject/bignumber';

type Numerable = number | string | BigNumber | EthersBigNumber

export const toBN = (val: Numerable, dividerDecimals = 0): BigNumber | undefined => {
  if (Number.isNaN(Number(val))) {
    return undefined;
  }
  return val instanceof BigNumber ?
    val.div(new BigNumber(10).pow(dividerDecimals))
    :
    new BigNumber(val.toString()).div(new BigNumber(10).pow(dividerDecimals));
};
export const formatBN = (val: Numerable, precision = 2, dividerDecimals = 0) =>
  Number((toBN(val, dividerDecimals)).toFixed(precision)).toString();

export const formatBNWithCommas = (val: Numerable, precision = 2, dividerDecimals = 0) =>
  Number.isNaN(Number(val)) ?
    'NaN'
    :
    Number((toBN(val, dividerDecimals)).toFixed(precision, BigNumber.ROUND_DOWN)).toLocaleString('en-US', { maximumFractionDigits: precision });

export const applyDecimals = (val: Numerable, decimals: number): BigNumber =>
  toBN(val).times(new BigNumber(10).pow(decimals));

export const removeDecimals = (val: Numerable, decimals: number): BigNumber =>
  toBN(val, decimals);