import React, { FC } from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { ThemeProvider } from '@mui/material/styles';
import { ToastProvider } from 'react-toast-notifications';


import ModalsWrap from '@components/ModalsWrap';
import CustomToast from '@components/CustomToast';

import { AuthorizationContextProvider } from '@contexts/AuthorizationContext';
import { AssetsInfoContextProvider } from '@contexts/AssertInfoContext';

import theme from '../../theme';

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider);
  library.pollingInterval = 1000;
  return library;
}

const Providers: FC = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      {/* <RefreshContextProvider> */}
      <AuthorizationContextProvider>
        <AssetsInfoContextProvider>
          <ThemeProvider theme={theme}>
            <ToastProvider
              autoDismiss
              autoDismissTimeout={6000}
              placement="bottom-right"
              components={{ Toast: CustomToast }}
            >
              <ModalsWrap>
                {children}
              </ModalsWrap>
            </ToastProvider>
          </ThemeProvider>
        </AssetsInfoContextProvider>
      </AuthorizationContextProvider>
    </Web3ReactProvider>
  );
};

export default Providers;
