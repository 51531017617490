import React, { FC, isValidElement, cloneElement } from 'react';

import { ModalsContext, useModalContext } from '@hooks/useModal';

import { useStyles } from './styles.module';

const ModalsWrap: FC = ({ children }) => {
  const { isOpen, content, handlePresent, handleDismiss } = useModalContext();
  const classes = useStyles();

  return (
    <ModalsContext.Provider
      value={{
        content,
        isOpen,
        onPresent: handlePresent,
        onDismiss: handleDismiss,
      }}
    >
      {children}
      {isOpen && (
        <div className={classes.modalWrap}>
          <div onClick={handleDismiss} className={classes.modalBackdrop}/>
          {isValidElement(content) &&
          cloneElement(content, {
            onDismiss: handleDismiss,
          })}
        </div>
      )}
    </ModalsContext.Provider>
  );
};

export default ModalsWrap;
